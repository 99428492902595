<template>
  	<div class="animated fadeIn">
		<div class="shadow-lg rounded">
			<b-overlay id="overlay-background" :show="isBusy" variant="secondary" opacity="0.8" blur="2px" rounded="sm">
				<b-table ref="table" fixed small striped show-empty :items="provider" :fields="fields":busy.sync="isBusy" :current-page="currentPage" :per-page="perPage" :sort-by.sync="sortedBy" :sort-desc.sync="sortedDesc" :no-border-collapse="false" head-variant="light">
					<!-- <template v-slot:cell(total_devices)="data">
						{{ data.item.total_devices > 0 ? data.item.total_devices : "None" }}
					</template> -->
					<template v-slot:cell(actions)="row">
						<!-- <b-button :disabled="row.item.device_set.length < 1" pill size="sm" variant="primary" @click="row.toggleDetails" v-b-tooltip.hover title="Expand devices"> <i class="fa fa-ellipsis-h"></i></b-button> -->
						<b-button class="mx-1" size="sm" variant="primary" :to="`edit/${row.item.id}`" v-b-tooltip.hover title="Edit Client"><i class="fa fa-pencil"></i></b-button>
					</template>
					<template v-slot:custom-foot="scope">
						<b-tr class="border">
							<b-td colspan="1" class="align-middle pl-2" variant="secondary"> Records {{ startIndex }} tot {{ endIndex }} van {{ totalRows }} </b-td>
							<b-td colspan="3" class="align-middle" variant="secondary">
								<b-pagination v-if="showPagination" class="m-0" prev-text="Prev" next-text="Next" hide-goto-end-buttons align="middle" :total-rows="totalRows" v-model="currentPage" :per-page="perPage" />
							</b-td>
						</b-tr>
					</template>
				</b-table>
				<b-row>
					<b-col>
						<b-button class="mx-1 my-1" variant="primary" :to="`/dashboard/client/add`">Add Client</b-button>
					</b-col>
				</b-row>
			</b-overlay>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex"
import ViewExtension from "../ViewExtension"

export default ViewExtension.extend({
	name: "ClientOverview",
	components: {
	},
	data() {
		return {
			fields: [
				{ key: "username", label: "Client" },
				{ key: "code", label: "Code" },
				{ key: "company.name", label: "Company" },
				{ key: "actions", label: "Actions" },
			],
			// slots: [{ key: "temp1" }, { key: "temp2" }, { key: "temp3" }, { key: "lock" }, { key: "location" }, { key: "orientation" }, { key: "timestamp" }, { key: "total_devices" }],

			currentPage: 1,
			perPage: 10,
			sortedBy: "id",
			sortedDesc: true,
			totalRows: 0,
		}
	},
	computed: {
		...mapState("auth", {
			current: (state) => state.current,
		}),
		startIndex() {
			return (this.currentPage - 1) * this.perPage + 1
		},
		endIndex() {
			var end = this.totalRows
			if (this.perPage < this.totalRows) {
				end = this.perPage * this.currentPage
				if (end > this.totalRows) {
					end = this.totalRows
				}
			}
			return end
		},
		showPagination() {
			return this.perPage < this.totalRows
		},
	},
	methods: {
		...mapActions("meds2go/client", ["clientDevices"]),
		provider(ctx, callback) {
			// this.loading()
			this.clientDevices({
				context: {},
				page: ctx.currentPage,
				per_page: ctx.perPage,
				sort_by: ctx.sortBy,
				sort_desc: ctx.sortDesc,
			}).then((results) => {
				callback(results)
				// console.log(results)
				this.totalRows = results.length
			})
			// this.stopLoading()
		},
		onClickCharts(row) {
			if (row.item.hasOwnProperty("id")) {
				this.$router.push(`/client/charts/${row.item.id}`)
			}
		},
		onClickEditDevice(row) {
			if (row.item.hasOwnProperty("id")) {
				this.$router.push(`/device/${row.item.id}/modify`)
			}
		},
		combineLocation(data) {
			let long = data.longitude
			let lat = data.latitude
			return long.toString() + "/" + lat.toString()
		},
	},
})
</script>
<style type="text/css">
b-tr {
	.no-hover {
		background-color: #ffffff;
	}
}
</style>
